<template>
  <app-modal-payment-link-detail
    :show="showModalDetail"
    @close="showModalDetail = false"
    :payment_link="selectPaymentLink"
    :resendEmailButton="false"
    :showBusinessDetails="isSupportRole"
    @update:payment_link="selectPaymentLink = $event"
  />
  <app-layout class="bg-background">
    <div class="container mx-auto">
      <div class="mx-4 my-5 flex flex-row justify-between items-center">
        <span class="text-xl">{{ $t("support.payment_link_title") }}</span>
      </div>
      <hr class="mx-4 w-full border-b border-gray-200 mb-5" />
      <!-- Download CSV Modal -->
      <app-modal
        :show="show_modal_download_csv"
        @close="show_modal_download_csv = false"
      >
        <template v-slot:title>
          {{ $t("support.download_csv_title") }}
        </template>
        <template v-slot:body>
          <p class="text-sm text-gray-500">
            {{ $t("support.download_csv_description") }}
          </p>
        </template>
        <template v-slot:action>
          <app-button
            @click="downloadCsvPaymentLink"
            :showf70Icon="false"
            type="button"
            class="sm:ml-3 sm:w-auto"
          >
            {{ $t("general.download_csv") }}
          </app-button>
        </template>
      </app-modal>
      <app-table
        :apiResponse="apiPaginationResponse.data"
        :loading="apiPaginationLoading"
        @params-changed="paramsChanged"
        @on-item-click="onItemClick"
        :filters="[
          {
            key: 'filter[title]',
            type: 'text',
            placeholder: $t('payment.link.header.title'),
          },
          {
            key: 'filter[email]',
            type: 'text',
            placeholder: $t('payment.link.header.email'),
          },
          {
            key: 'filter[currency]',
            type: 'text',
            placeholder: $t('support.currency_placeholder'),
          },
          {
            key: 'filter[amount]',
            type: 'number',
            placeholder: $t('wallet.transaction.amount'),
          },
          {
            key: 'filter[business_name]',
            type: 'text',
            placeholder: $t('payout.table.account.header_business_name'),
          },
          {
            key: 'filter[business_email]',
            type: 'text',
            placeholder: $t('support.business_support_email_placeholder'),
          },
          {
            key: 'filter[payment_id]',
            type: 'text',
            placeholder: $t('support.payment_id_placeholder'),
          },
          {
            key: 'filter[payment_link_id]',
            type: 'text',
            placeholder: $t('support.payment_link_id_placeholder'),
          },
          {
            key: 'filter[attempt_id]',
            type: 'text',
            placeholder: $t('support.payment_attempt_id_placeholder'),
          },
          {
            key: 'filter[reference]',
            type: 'text',
            placeholder: $t('support.ref_1_placeholder'),
          },
          {
            key: 'filter[reference_2]',
            type: 'text',
            placeholder: $t('support.ref_2_placeholder'),
          },
          {
            key: 'filter[created_at_between]',
            type: 'date',
          },
        ]"
      >
        <template v-slot:button>
          <app-button
            @click="show_modal_download_csv = true"
            :showf70Icon="false"
            type="button"
            class="sm:ml-3 sm:w-auto"
          >
            {{ $t("payout.modal.download_csv_button") }}
          </app-button>
        </template>
        <template v-slot:header>
          <th class="font-bold">{{ $t("payment.link.header.amount") }}</th>
          <th class="font-bold">&nbsp;</th>
          <th class="font-bold">{{ $t("payment.link.header.title") }}</th>
          <th class="font-bold">{{ $t("payment.link.header.email") }}</th>
          <th class="font-bold">{{ $t("payment.link.header.date") }}</th>
          <th class="font-bold">
            {{ $t("payment.link.header.payment_link") }}
          </th>
        </template>
        <template v-slot:body="data">
          <td>
            <p class="font-medium">
              {{
                $formats.currency(data.model.currency, data.model.amount ?? 0.0)
              }}
            </p>
          </td>
          <td class="flex place-items-center space-x-2 justify-left">
            <app-badge
              :status="data.model.paid ? 'success' : 'light'"
              class="w-16 text-xs"
            >
              {{
                data.model.paid
                  ? $t("payment.detail.paid")
                  : $t("payment.detail.unpaid")
              }}
            </app-badge>
            <app-badge
              v-if="data.model.settlement != null"
              :status="'success'"
              class="w-18 text-xs"
            >
              {{ $t("payment.detail.processed") }}
            </app-badge>
          </td>
          <td>
            {{ data.model.title }}
          </td>
          <td>
            {{ data.model.email }}
          </td>
          <td>
            {{ $moment(data.model.created_at).format("DD MMM YYYY hh:mm a") }}
          </td>
          <td>
            <div class="flex flex-row space-x-1">
              <button @click.stop="copyUrl(data.model)">
                <div
                  class="
                    px-2
                    py-1
                    border
                    rounded-md
                    flex flex-row
                    justify-center
                    bg-background
                    place-items-center
                    hover:bg-gray-100
                  "
                >
                  <app-icon
                    name="ClipboardCopyIcon"
                    class="h-5 w-5 text-gray-400"
                  />
                  <p class="ml-1 text-xs">
                    {{ $t("payment.detail.copy_link") }}
                  </p>
                </div>
              </button>
            </div>
          </td>
        </template>
      </app-table>
    </div>
  </app-layout>
</template>

<script>
import ROLE from "@/utils/const/role";
export default {
  data() {
    return {
      ROLE: ROLE,
      showModalDetail: false,
      selectPaymentLink: null,
      show_modal_download_csv: false,
      queryParams: [],
    };
  },

  mounted() {
    this.$store.dispatch(
      "paymentStore/getListPaymentLinksForSupport"
    );
  },

  computed: {
    apiPaginationResponse() {
      return this.$store.getters["paymentStore/apiPaginationResponse"];
    },
    apiPaginationLoading() {
      return this.$store.getters["paymentStore/apiPaginationLoading"];
    },
    selectedRole() {
      return this.$store.getters["authStore/selectedRole"];
    },
  },

  methods: {
    fetchPaymentLink() {
      this.$store.dispatch("paymentStore/getSupportPaymentLinkExports", this.queryParams);
    },
    copyUrl(model) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;

      clipboardData.writeText(model.payment_url);

      this.$notify(
        {
          group: "success",
          title: this.$t("payment.link.copy_link"),
        },
        5000
      );
    },

    paramsChanged(filters, queryParams) {
      this.queryParams = queryParams;
      this.$store.dispatch(
        "paymentStore/getListPaymentLinksForSupport",
        queryParams
      );
    },

    isSupportRole() {
      return [ROLE.SUPPORT].includes(this.select_role?.id);
    },

    onItemClick(data) {
      this.showModalDetail = !this.showModalDetail;
      this.selectPaymentLink = data;
    },

    async downloadCsvPaymentLink() {
      this.show_modal_download_csv = false;
      this.submitLoading = true;
      const csv_url = await this.$store.dispatch(
        "paymentStore/getSupportPaymentLinkExports",
        this.queryParams
      );

      this.submitLoading = false;

      if (csv_url.data) {
        window.location.href = csv_url.data;
      }
    },
  },
};
</script>